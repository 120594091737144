module.exports = `
<p>
PLEASE READ THE FOLLOWING FULL RISK DISCLOSURE CAREFULLY BEFORE USING THIS
WEBSITE, INDICATORS, OR PRODUCTS. All users of this site agree that access to
and use of this site, indicators, or products are subject to the following risk
disclosures and other applicable law. If you do not agree to these disclosures,
please do not use this site, indicators, nor products contained within. Wizbord
Inc., Wizdough (henceforth referred to as “Author”) is not a registered
investment advisor or broker dealer and does not provide investment advice to
individuals. None of the website content, trading room, blogs, software,
indicators, email newsletters, affiliated social media pages to include, but not
limited to, Facebook, YouTube, and Twitter, 3rd party forums, Webinars, voice
chat room content, or communication from Author otherwise (henceforth referred
to as “Author Content”) should be construed as a recommendation or solicitation
to buy or sell any security or as investment advice. All Author Content is
provided for information and educational purposes only. Your investment
decisions are solely your responsibility and it is highly recommended that you
consult a licensed financial advisor or broker, attorney, or your CPA, before
making any and all investment or financial decisions.
</p>
<p>
Always seek advice and guidance from professional, licensed, and registered
investment advisors, financial advisors, tax advisors, and legal advisors prior
to making any investment decisions. By viewing Author Content or any content
created or replicated by Author, you acknowledge that you understand and accept
the disclosures herein. If any provision in these disclosures is found to be
invalid, unenforceable, or nonsensical, the remaining provisions will continue
in full force and effect.
</p>
<h4><strong>Trading Signals</strong></h4>
<p>
Author Content, its members’ room, and all Author Content which include, but are
not limited to, blogs, emails, posts, newsletters, indicators, software, and
through means of various affiliated social media sites, (may) provide live
trading signals. All alerts are for educational purposes only and are not to be
construed as an offer to buy nor sell futures contracts or any other financial
instruments. By accessing this website or any of the mediums Author utilizes for
displaying its trading signals mentioned above, you hereby agree that you will
not make actual trades based on any information, educational alerts, or triggers
that are posted in any education, via email or discussed via audio/video/text in
the chat room or in any of the Author Content – this is not a financial advisory
service. Note that all of our posts are for educational use only; per CFTC/SEC
guidelines we do not give investment advice nor promote the buying nor selling
of any specific futures contract(s). Traders assume full liability for all
trading risks and outcomes. Do not make actual trades based on information or
alerts based on any information from Author, nor our members’ room discussions
nor based on an Author Content. We are an online educational source for learning
to trade the forex and futures contracts. Author or its members, employees, and
associates are not responsible for any loss or profit.
</p>
<h4><strong>U.S. Government Disclaimer</strong></h4>
<p>
U.S. Government Required Disclaimer – Commodity Futures Trading Commission
Futures and Options trading have large potential rewards, but also large
potential risk. You must be aware of the risks and be willing to accept them in
order to invest in the futures and options markets. Don’t trade with money you
can’t afford to lose. This is neither a solicitation nor an offer to Buy/Sell
futures or options. No representation is being made that any account will or is
likely to achieve profits or losses similar to those discussed on this web site.
The past performance of any trading system or methodology is not necessarily
indicative of future results.
</p>
<h4><strong>Full Risk Disclosure</strong></h4>
<p>
FULL RISK DISCLOSURE – Futures trading contains substantial risk and is not for
every investor. An investor could potentially lose all or more than the initial
investment. Risk capital is money that can be lost without jeopardizing one’s
financial security or life style. Only risk capital should be used for trading
and only those with sufficient risk capital should consider trading. Past
performance is not necessarily indicative of future results.
</p>
<h4><strong>SEC Disclaimer</strong></h4>
<p>
Author generates trading signals and are not investment advice. We have no
financial interest in the outcome of any trades mentioned herein, other than
disclosed in these Terms of usage and/or Sales agreement. There is a substantial
risk of loss trading futures and commodities. You need to determine your own
suitability to trade them. There may be tax consequences for short term
profits/loss on trades. Consult your tax advisor for details on this if
applicable.
</p>
<h4><strong>Commodity Futures Trading Commission and Other
Disclaimers</strong></h4>
<p>
Neither Author, nor its principals, members, officers or employees are CTAs.
Purchasing Author products and/or otherwise relying on Author Content presumes
you have fully read and understood the risks involved in trading
forex/futures/commodities/securities as set forth below and in these terms and
conditions in its entirety:
</p>
<p>
These results are based on simulated or hypothetical performance results that
have certain inherent limitations. Unlike the results shown in an actual
performance record, these results do not represent actual trading. Also, because
these trades have not actually been executed, these results may have under-or
over-compensated for the impact, if any, of certain market factors, such as lack
of liquidity. Simulated or hypothetical trading programs in general are also
subject to the fact that they are designed with the benefit of hindsight. No
representation is being made that any account will or is likely to achieve
profits or losses similar to these being shown.
</p>
<p>
There are no guarantees or certainties in trading. Reliability of trading
signals for mechanical systems is in probabilities only. Trading involves hard
work, risk, discipline and the ability to follow rules and trade through any
tough periods during system draw-downs. If you are looking for guarantees,
trading is not for you. Most people lose money when trading. One of the reasons
is that they lack discipline and are unable to be consistent. A system can help
you become consistent. The ability to be disciplined and take the trades is
equally as important as any technical indicators a trader uses. Ironically,
worrying about the money aspect of trades can contribute to and cause a trader
to make trading errors. Therefore, it is important to only trade with true risk
capital.
</p>
<p>
CFTC RULE 4.41 – HYPOTHETICAL OR SIMULATED PERFORMANCE RESULTS HAVE CERTAIN
LIMITATIONS. UNLIKE AN ACTUAL PERFORMANCE RECORD, SIMULATED RESULTS DO NOT
REPRESENT ACTUAL TRADING. ALSO, SINCE THE TRADES HAVE NOT BEEN EXECUTED, THE
RESULTS MAY HAVE UNDER-OR-OVER COMPENSATED FOR THE IMPACT, IF ANY, OF CERTAIN
MARKET FACTORS, SUCH AS LACK OF LIQUIDITY. SIMULATED TRADING PROGRAMS IN GENERAL
ARE ALSO SUBJECT TO THE FACT THAT THEY ARE DESIGNED WITH THE BENEFIT OF
HINDSIGHT. NO REPRESENTATION IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY TO
ACHIEVE PROFITS OR LOSSES SIMILAR TO THOSE SHOWN.
</p>
<p>
HYPOTHETICAL PERFORMANCE RESULTS HAVE MANY INHERENT LIMITATIONS, SOME OF WHICH
ARE DESCRIBED BELOW. IN FACT, THERE ARE FREQUENTLY SHARP DIFFERENCES BETWEEN
HYPOTHETICAL PERFORMANCE RESULTS AND THE ACTUAL RESULTS SUBSEQUENTLY ACHIEVED BY
ANY PARTICULAR TRADING PROGRAM.
</p>
<p>
ONE OF THE LIMITATIONS OF HYPOTHETICAL PERFORMANCE RESULTS IS THAT THEY ARE
GENERALLY PREPARED WITH THE BENEFIT OF HINDSIGHT. IN ADDITION, HYPOTHETICAL
TRADING DOES NOT INVOLVE FINANCIAL RISK, AND NO HYPOTHETICAL TRADING RECORD CAN
COMPLETELY ACCOUNT FOR THE IMPACT OF FINANCIAL RISK OF ACTUAL TRADING. FOR
EXAMPLE, THE ABILITY TO WITHSTAND LOSSES OR TO ADHERE TO A PARTICULAR TRADING
PROGRAM IN SPITE OF TRADING LOSSES ARE MATERIAL POINTS WHICH CAN ALSO ADVERSELY
AFFECT ACTUAL TRADING RESULTS. THERE ARE NUMEROUS OTHER FACTORS RELATED TO THE
MARKETS IN GENERAL OR TO THE IMPLEMENTATION OF ANY SPECIFIC TRADING PROGRAM
WHICH CANNOT BE FULLY ACCOUNTED FOR IN THE PREPARATION OF HYPOTHETICAL
PERFORMANCE RESULTS AND ALL OF WHICH CAN ADVERSELY AFFECT TRADING RESULTS.
</p>
`;